import React, { useState, Component } from 'react';
import { Input, Button, Form, message, Select, Image } from 'antd';
import styles from './LoginFrom.module.css';
import history from '../../config/history';
import logo from '../../resource/png/mmLogo.png';
import { postlogin, getRegisterCode, postRegister, getMdypwdCode, putMdypwd } from '../../services/login';
import { setDepartment_Data, setUser, setSession, setOssBaseAuth, setBalance } from '../../config/storage';
import { LoadingOutlined, RollbackOutlined } from '@ant-design/icons';
import Regin from '../../components/region';

const { Item } = Form;
const phone = /^[1][0-9]{10}$/;
const { Option } = Select;

class LoginFrom extends Component {
  constructor(props) {
    super(props);
    this.state = {
      signin: false,
      signup: true,
      loading: false,
      registerType: false,
      count: 0,
      uid1: 0,
      shopID: 0,
      serviceType: ['窗帘'],
      registerInput: {
        id: 0,
        uid: '',
        pwd: '',
        code: '',
        department: 1154,
        name: '',
        province: '北京市',
        city: '北京市',
        district: '朝阳区',
        address: '',
      },
    };
  }
  render() {
    const { signin, signup, loading, registerType, count, uid1, shopID, serviceType, registerInput, } = this.state;
    return (
      <div className='col'>
        <div className={`row w900 ${registerType ? 'title2' : 'title'}`}>
          <Image src={logo} preview={false} />
          <div className='w100 tc color8 bold fz60 margin-l40'>墨墨管家商户订单系统</div>
        </div>
        <div className='row' >
          {!registerType && <div className={`${styles['container_position']} row`}>
            <Form className='row' onFinish={this.login}>
              <div className={`${styles['container_signin']} ${styles['container_form']} col-t  ${signin ? styles['animation-slide-right'] : styles['animation-slide-left']}`} style={{ opacity: !signin ? 1 : 0 }}>
                <div className={styles['form_title']}>登录</div>
                <Item
                  name="uid"
                  rules={[
                    {
                      required: true,
                      message: '请输入您的登陆账号'
                    }
                  ]}
                >
                  <Input className={styles['input']} placeholder="请输入手机号" />
                </Item>
                <Item
                  name="pwd"
                  rules={[
                    {
                      required: true,
                      message: '请输入您的登陆密码'
                    }
                  ]}
                >
                  <Input.Password className={styles['input']} autoComplete='off' placeholder="请输入密码" visibilityToggle={false} />
                </Item>
                <div className={styles['link']} onClick={this.onSetSingnup}>忘记密码?</div>
                <Item>
                  <Button className={styles['btn']} htmlType='submit'>
                    登录
                    {
                      loading && <LoadingOutlined style={{ fontSize: 16 }} spin />
                    }
                  </Button>
                </Item>
              </div>
            </Form>
            {/* <Form className='row' onFinish={register}> */}
            <Form className='row' onFinish={this.forgetPwd}>
              <div className={`${styles['container_signup']} ${styles['container_form']} col-t ${signup ? styles['animation-slide-left'] : styles['animation-slide-right']}`} style={{ opacity: !signup ? 1 : 0 }}>
                <div className={styles['form_title']}>忘记密码</div>
                <Item
                  name="uid1"
                  rules={[
                    {
                      required: true,
                      message: '请输入您的登陆账号'
                    }
                  ]}
                >
                  <Input className={styles['input']} name='forgetPwd' placeholder="请输入手机号" onBlur={this.getuid1} />
                </Item>
                <Item
                  name="register"
                  rules={[
                    {
                      required: true,
                      message: '请输入验证码'
                    }
                  ]}
                >
                  <div>
                    <Input className={styles['input']} placeholder="请输入验证码" autoComplete='off' />
                    <Button className={styles['getCode']} onClick={this.getCode.bind(this, '重置密码')}>{count < 1 ? '发送验证码' : `${count}s`}</Button>
                  </div>
                </Item>
                <Item
                  name="pwd1"
                  rules={[
                    {
                      required: true,
                      message: '请输入您新的登陆密码'
                    }
                  ]}
                >
                  <Input.Password className={styles['input']} autoComplete='off' placeholder="请输入新密码" visibilityToggle={false} />
                </Item>
                <Button className={styles['btn']} htmlType='submit'>确认修改密码</Button>
              </div>
            </Form>
          </div>}
          {!registerType && <div className={`${styles['container_position']} row`}>
            <div className={`${signin ? styles['container_overlay_signin1'] : styles['container_overlay_signin']} col`} style={{ opacity: signin ? 1 : 0 }}>
              <Button className={styles['btn']} onClick={this.onSetSingnin}>登录</Button>
            </div>
            <div className={`${signup ? styles['container_overlay_signup1'] : styles['container_overlay_signup']} col`} style={{ opacity: signup ? 1 : 0 }}>
              <Button className={styles['btn']} onClick={this.onRegister}>注册</Button>
            </div>
          </div>}
          {
            registerType && <div className={`${styles['container_register']} ${styles['flip-horizontal-bottom-register']} padding20`}>
              <div className={`${styles['register_title']} row`}>注册</div>
              <div>
                <div>①请填写账户信息:</div>
                <div className='row-l margin-t10'>
                  <Input className={`${styles['register_input']}`} placeholder="请输入手机号" onBlur={this.onRegisterInout.bind(this, 'uid')} name='registerCode' />
                  <Input className={`${styles['register_input']}`} placeholder="请设置密码" onBlur={this.onRegisterInout.bind(this, 'pwd')} />
                  <div className='row-l'>
                    <Input className={`${styles['register_input']}`} placeholder="请输入验证码" onBlur={this.onRegisterInout.bind(this, 'code')} />
                    <Button className={styles['getCode1']} onClick={this.getCode.bind(this, '注册')} >{count < 1 ? '发送验证码' : `${count}s`}</Button>
                  </div>
                </div>
              </div>
              <div className='margin-t10'>
                <div>②请填写店铺信息:</div>
                <div className='row-l margin-t10'>
                  <Input className={`${styles['register_input']}`} placeholder="请输入店铺名称" onBlur={this.onRegisterInout.bind(this, 'name')} />
                  <Regin value={[registerInput.province, registerInput.city, registerInput.district]} widths={'w180'}
                    onChange={this.onReginChange.bind(this)} />
                </div>
                <Input className={`${styles['register_input_address']}`} placeholder="请输入详细地址" onBlur={this.onRegisterInout.bind(this, 'address')} />
              </div>
              <div className='margin-t10 '>
                <div>③添加商户行业</div>
                <Select
                  onChange={this.changeType}
                  value={serviceType}
                  size={'large'}
                  className={`${styles['w845']}`}
                  mode="multiple"
                  allowClear
                // style={{ width: '100%' }}
                // defaultValue={['a10', 'c12']}
                >
                  <Option value="窗帘">窗帘</Option>
                  <Option value="墙布墙纸">墙布墙纸</Option>
                  <Option value="晾衣架">晾衣架</Option>
                  <Option value="地板">地板</Option>
                  <Option value="其他">其他</Option>
                </Select>

              </div>
              <div className='margin-t20'>
                <Button className={`${styles['button_return']}`} onClick={this.setFalseRegister} icon={<RollbackOutlined />}>返回登陆</Button>
                <Button className={`${styles['button_submit']}`} onClick={this.register}>确认并提交</Button>
              </div>
            </div>
          }
        </div>
      </div>
    );
  }
  onRegisterInout = (type, e) => {
    let data = { ...this.state.registerInput };
    data[type] = e.target.value;
    this.setState({
      registerInput: data
    })
  }
  onReginChange = (e) => {
    let data = { ...this.state.registerInput };
    data.province = e[0];
    data.city = e[1];
    data.district = e[2];
    this.setState({
      registerInput: data
    });
  }
  onRegister = () => {
    this.setState({
      registerType: !this.state.registerType
    })
  }
  setFalseRegister = () => {
    this.setState({
      registerType: false
    })
  }
  changeType = (e) => {
    this.setState({
      serviceType: e
    })
  }
  //切换
  onSetSingnup = () => {
    this.setState({
      signin: !this.state.signin,
      signup: !this.state.signup,
    })
  }
  //切换
  onSetSingnin = () => {
    this.setState({
      signin: !this.state.signin,
      signup: !this.state.signup,
    })
  }
  //获取手机号
  getuid1 = (e) => {
    this.setState({
      uid1: e.target.value
    })
  }
  //获取验证码
  getCode = async (type) => {
    const { count, } = this.state;
    message.warn('由于网站遭受恶意攻击，暂时无法接收验证码，注册请联系刘经理 电话：19337777313（微信同号）');
    return;
    if (count > 0) {
      return;
    }
    let val;
    if (type === '重置密码') {
      let inputEl = document.querySelector('[name="forgetPwd"]');
      val = inputEl.value;
    } else {
      let inputEl = document.querySelector('[name="registerCode"]');
      val = inputEl.value;
    }
    if (!phone.test(val)) {
      message.warn('手机号码不符合规则');
      return;
    }
    let department = 1154;
    let res;
    if (type === '重置密码') {
      res = await getMdypwdCode(department, val)
    } else {
      res = await getRegisterCode(department, val);
    }
    if (res.status !== 200) {
      message.error(res.data);
      return;
    }
    this.setState({
      shopID: res.data,
      counts: 60,
    }, () => this.countdown(60))
  }
  //倒计时
  countdown = (counts) => {
    const f1 = () => {
      if (counts > 0) {
        counts--;
        this.setState({
          count: counts
        });
      } else {
        window.clearTimeout(t1);
      }
    };
    let t1 = window.setInterval(f1, 1000);
  }
  //登录
  login = async (data) => {
    const { } = this.state;
    this.setState({
      loading: true,
    });
    data.department = 1154;
    let res = await postlogin(data);
    if (res.status !== 200) {
      this.setState({
        loading: false,
      });
      message.error(res.data)
      return;
    }
    setDepartment_Data(res.data);
    setUser(res.data.key);
    setSession(res.data.session);
    setBalance(res.data.balance)
    this.setState({
      loading: false,
    });
    let oss = {
      host: res.data.service + '/',
      expiration: 0,
      key: '',
      policy: '',
      prefix: '',
      signature: '',
    }
    setOssBaseAuth(oss);
    history.push('/');
  }
  //忘记密码
  forgetPwd = async (data) => {
    this.setState({
      loading: true,
    });
    const { shopID } = this.state;
    let newData = {
      id: shopID,
      appid: data.uid1,
      pwd: data.pwd1,
      code: data.register,
    }
    let res = await putMdypwd(newData);
    if (res.status !== 204) {
      message.error(res.data);
      this.setState({
        loading: false,
      });
      return;
    }
    message.success('密码修改成功')
    this.setState({
      signin: !this.state.signin,
      signup: !this.state.signup,
      loading: false,
    })
  }
  //注册
  register = async () => {
    const { shopID, serviceType, registerType } = this.state;
    let data = { ...this.state.registerInput };
    if (data.uid === '') {
      message.error('请设置手机号');
      return;
    }
    if (data.pwd === '') {
      message.error('请设置密码');
      return;
    }
    if (data.code === '') {
      message.error('请输入验证码');
      return;
    }
    if (data.name === '') {
      message.error('请输入店铺名称');
      return;
    }
    if (serviceType.length === 0) {
      message.error('请选择行业');
      return;
    }
    data.id = shopID;
    data.shopName = data.name;
    data.service_type = JSON.stringify(serviceType);
    let res = await postRegister(data);
    if (res.status !== 200) {
      message.error(res.data);
      return;
    }
    this.setState({
      registerType: !registerType
    })
  }
}
export default LoginFrom;
// export default connect(({ orderRenter }) => (orderRenter))(LoginFrom);