import React, { Component } from 'react';
import { Form, Input, Button, Drawer, Switch, Select, message } from 'antd';
import UploadPic from '../../components/UploadPic';
import { postAddUser, putMdyUser, putResetpwd } from '../../services/storeManagement';
import { getDepartment_Data } from '../../config/storage';
import styles from './index.module.css'

const { Option } = Select;
const layout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 18 },
};
class IndexModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      resetPwd: false,
      data: {},
    };
    this.baseUrl = getDepartment_Data().service;
  }
  form = React.createRef()
  // function IndexModal({ cRef, updateTable }) {
  // const [form] = Form.useForm();

  // useImperativeHandle(cRef, () => ({
  //   showModal: (data) => {
  //     setData(data)
  //     form.setFieldsValue(data)
  //   }
  // }));
  render() {
    const { visible, resetPwd, data } = this.state;
    return (
      <Drawer
        title='员工账户维护'
        width={600}
        visible={visible}
        onClose={this.onCancel}
        footer={
          <div className='row-r w100'>
            <Button className='margin-r10' onClick={this.onCancel}>返回</Button>
            <Button onClick={this.onFinish} type='primary' htmlType="submit">保存</Button>
          </div>
        }
      >
        <div className='col-t vertical-lt'>
          <div className='row-l margin-b20'>
            <div className='w90'>姓名：</div>
            <Input value={data.name} className='w400' onChange={this.changeSum.bind(this, 'name')} />
          </div>
          <div className='row-l margin-b20'>
            <div className='w90'>手机：</div>
            <Input value={data.phone} className='w400' onChange={this.changeSum.bind(this, 'phone')} />
          </div>
          {
            data.id === 0 &&
            <div className='row-l margin-b20'>
              <div className='w90'>微信：</div>
              <Input value={data.weixin} className='w400' onChange={this.changeSum.bind(this, 'weixin')} />
            </div>
          }
          {
            data.id === 0 &&
            <div className='row-l margin-b20'>
              <div className='w90'>权限：</div>
              <Select value={data.role} onChange={this.onRole.bind(this)} className='w400'>
                <Option value='合伙人'>合伙人</Option>
                <Option value='会员'>会员</Option>
                <Option value='加盟商'>加盟商</Option>
                <Option value='普通用户'>普通用户</Option>
              </Select>
            </div>
          }
          <div className='row-l margin-b20'>
            <div className='w90'>是否启用：</div>
            <Switch checkedChildren="是" unCheckedChildren="否" checked={data.enable === '是' ? true : false}
              onChange={this.onSwitch.bind(this)} />
          </div>
          {
            data.id !== 0 &&
            <div className='row-l margin-b20'>
              <div className='w90'>重置密码：</div>
              <Switch checkedChildren="是" unCheckedChildren="否" checked={resetPwd} onChange={this.onResetPwd.bind(this)} />
              <div className={styles['remarks']}>注：重置后密码为123456</div>
            </div>
          }
        </div>
      </Drawer>
    );
  }
  changeSum = (type, e) => {
    let data = { ...this.state.data };
    data[type] = e.target.value ? e.target.value : '';
    this.setState({
      data
    });
  }
  showModal = (data) => {
    this.setState({
      visible: true,
      data: data
    });
  }
  upLoadSku = (e) => {
  }
  onRole = (e) => {
    let newData = { ...this.state.data };
    newData.role = e
    this.setState({
      data: newData
    });
  }
  onResetPwd = (e) => {
    this.setState({
      resetPwd: e
    });
  }
  onFinish = async () => {
    let newData = { ...this.state.data };
    let res;
    if (newData.id !== 0) {
      res = await putMdyUser(newData.id, newData.name, newData.phone, newData.enable)
      if (this.state.resetPwd) {
        let resPwd = await putResetpwd(newData.id);
        if (resPwd.status !== 204) {
          message.error(res.data);
          return
        }
      }
      if (res.status !== 204) {
        message.error(res.data);
        return
      }
    } else {
      newData.uid = newData.phone;
      res = await postAddUser(newData)
      newData.id = res.data;
      if (res.status !== 200) {
        message.error(res.data);
        return
      }
    }
    this.props.updateTable(newData)
    this.setState({
      visible: false
    })
  }
  onSwitch = (e) => {
    let newData = { ...this.state.data };
    newData.enable = e ? '是' : '否';
    this.setState({
      data: newData
    });
  }
  onCancel = () => {
    this.setState({
      visible: false
    })
  }
}

export default IndexModal;