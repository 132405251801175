// import React, { useState, useImperativeHandle, useEffect } from 'react';
import React, { Component } from 'react';
import { Modal, Image, Radio, Upload, Popconfirm, message, Input, Table, Spin, InputNumber, Button } from 'antd';
import { getOrder, putOrder } from '../../services/orderRenter';
import Regin from '../../components/region';
import convert from '../../utils/convert';
import { ossUpload, } from '../../utils/ossUpload.js';
import NewOrder from '../newOrder';
import { getShopVideos } from '../../services/storeManagement';
import { analysisAddress, getCategory, getPrice, getVideoInfo, getVideoPlay, getVideoSub } from '../../services/newOder';
import styles from './index.module.css';
import { DeleteOutlined, EyeOutlined, PlusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { getDepartment_Data } from '../../config/storage';


const serviceDatas = {
  sort: 0,
  id: 0,
  order: 0,
  room: '窗户1',
  width: '1',
  width_remark: '',
  height: '0',
  height_remark: '',
  prop1: '',
  prop2: '1',
  prop3: '',
  prop4: '',
  prop5: '',
  prop6: '',
  prop7: '',
  prop8: '',
  prop9: '',
  prop10: '',
  prop11: '',
  prop12: '',
  prop13: '',
  prop14: '',
  prop15: '',
  state: '已确认',
  pic: '',
  remark: '',
}

const modals = ['订单详情', '修改记录', '售后记录'];
const roomType = ['主卧', '次卧', '客厅', '厨房', '客餐厅', '阳台', '书房', '卫生间', '儿童房', '餐厅', '保姆房', '商务年会'];

class IndexModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      visibleName: false,
      spinningTable: false,
      spinning: false,
      id: 0,
      specialPrice: 0,
      data: {
        province: '北京市',
        city: '北京市',
        district: '东城区',
        address: '',
        add_app: 0
      },
      dataIndex: {},
      datas: {},
      type: '测量',
      postData: [],
      specialRemark: '',
      tbRemark: '',
      videoList: [],
      selectVideo: [],
      videoPlay: [],

      groupType: [],
      detailType: [],
      detailID: [],
      // detailAdd: [],
      optionsRadio: [],
      orderPrice: [],
      orderPrice_oms: [],

      price_app: 0,

      modalIndex: 0,
      roomModalValue: '',
      roomModalValue: '窗帘1',

      modalType: '订单详情',
      modalsItem: '订单详情',
      columns: [],
      columnsDetails: [],
      serviceItemG: [],
      appraiseFee: {
        bonus_order: '否',
        shop: 0,
      },
    }

    //修改记录
    this.columnModify = [
      {
        title: '修改时间',
        align: 'center',
        dataIndex: 'submit_time',
        width: 100,
      },
      {
        title: '修改内容',
        align: 'center',
        dataIndex: 'content',
        width: 100,
        render: (text, record, index) => {
          let currentTxt = record.content;
          if (record.type === '预约') {
            let regObj = /[0-9]{2}:[0-9]{2}:[0-9]{2}/g;
            currentTxt = currentTxt.replace(regObj, '');
          }
          return (
            <div>{currentTxt}</div>
          );
        }
      },

    ]
    //售后记录
    this.columnService = [
      {
        title: '售后类型',
        align: 'center',
        dataIndex: 'order_type',
        width: 100,
      },
      {
        title: '售后原因',
        align: 'center',
        dataIndex: 'content',
        width: 100,
      },
      {
        title: '金额',
        align: 'center',
        dataIndex: 'price_app',
        width: 100,
      },
      {
        title: '提交时间',
        align: 'center',
        dataIndex: 'submit_time',
        width: 100,
      },
      {
        title: '状态',
        align: 'center',
        dataIndex: 'state',
        width: 100,
      },
    ]
    this.baseUrl = getDepartment_Data().service;
    this.shopId = getDepartment_Data().shopId;
  }
  componentDidMount() {
    // this.getVideoTemplate();
  }
  render() {
    const { visible, modalType, modalsItem, data, type, spinningTable, spinning, postData,
      roomModalValue, visibleName, columns, columnsDetails, videoList, selectVideo, videoPlay, appraiseFee } = this.state;
    return (
      <Modal
        title={modalType}
        width={1600}
        visible={visible}
        onCancel={this.onCancel}
        destroyOnClose
        // onOk={this.onOk}
        // okText='确定'
        // cancelText='取消'
        footer={
          <div className='w100 row-r'>
            <Button onClick={this.onCancel}>取消</Button>
            {data.state === '草稿箱' && modalType === '修改订单' &&
              <Button onClick={this.onOk.bind(this, '下单')}>下单</Button>
            }
            {
              modalType === '修改订单' &&
              <Button onClick={this.onOk.bind(this, '修改')}>保存</Button>
            }
          </div>
        }
      >
        <Spin tip="数据加载中..." spinning={spinning}>
          {
            modalType === '订单详情' ? (
              <div>
                <div className='row-l'>
                  {
                    modals.map((item, index) => {
                      return (
                        <div
                          key={index}
                          className={`${modalsItem === item ? styles['modals_choice_style'] : styles['modals_style']}`}
                          onClick={this.onModalsClick.bind(this, item)}
                        >
                          {item}
                        </div>
                      )
                    })
                  }
                </div>
                {
                  modalsItem === '订单详情' && <div>
                    <div className='row-l margin-t10'>
                      <div className={`${styles['order_details_style']}`}>姓名: {data.name}</div>
                      <div className={`${styles['order_details_style']}`}>手机号: {data.phone}</div>
                      <div className={`${styles['order_details_style']}`}>地址: {data.province + data.city + data.district + data.address}</div>
                    </div>
                    <div className='row-l margin-t10'>
                      <div className={`${styles['order_details_style']}`}>费用: {data.price_app}</div>
                      <div className={`${styles['order_details_style']}`}>特殊费用: {data.add_app}</div>
                      <div className={`${styles['order_details_style']}`}>总费用: {data.price_app + data.add_app}</div>
                      {
                        appraiseFee.bonus_order === '是' &&
                        <div className='row-l vertical-lt'>
                          <div className='fz18'>好评费用：{appraiseFee.shop}</div>
                        </div>
                      }
                    </div>
                    <div className='col vertical-lt'>
                      <div className={`${styles['order_details_style']} ${styles['display_hide']} margin-t10`}>师傅备注: {data.worker_remark}</div>
                      <div className={`${styles['order_details_style']} ${styles['display_hide']} margin-t10`}>特殊备注: {data.add_remark}</div>
                      <div className='col vertical-lt margin-t10'>
                        {
                          data.pic_remark && typeof data.pic_remark === 'object' &&
                          <div className='row-l'>
                            <div className='fz16 w90'>图片备注：</div>
                            {
                              <div className='row-w w1300'>
                                {
                                  data.pic_remark.map((item, index) => {
                                    return (
                                      <div key={index} className={`${styles['content']}`}>
                                        <div className={`row ${styles['pic-icon']}`}></div>
                                        <img
                                          alt="暂无图片"
                                          className={`${styles['pic2']}`}
                                          src={`${this.baseUrl}/upload/${item}`}
                                        />
                                        <div className={`row ${styles['content-div2']}`}>
                                          <EyeOutlined
                                            className={`${styles['pic-icon-E']}`}
                                            onClick={this.preview.bind(this, `${this.baseUrl}/upload/${item}`)}
                                            theme="outlined"
                                            title="预览"
                                          />
                                        </div>
                                      </div>
                                    );
                                  })
                                }
                              </div>
                            }
                          </div>
                        }
                        {
                          // selectVideo.length > 0 &&
                          <div className='row-l vertical-lt margin-t10'>
                            <div className='fz16 w90'>视频备注：</div>
                            <div className='col-t vertical-lt w100'>
                              <div className='row-w w900'>
                                {
                                  videoList.map((item, index) => {
                                    return (
                                      <div className={`${styles['video']} ${selectVideo.includes(index) ? styles['video-select'] : ''} none-select`}
                                        key={index} >
                                        {item.name}
                                      </div>
                                    );
                                  })
                                }
                              </div>
                              <div className='row-w vertical-lt w100'>
                                {
                                  videoPlay.map((item, index) => {
                                    return (
                                      <div className={`${styles['video-box']} col-t vertical-lt`} key={index}>
                                        <div className={`${styles['content-video']}`}>
                                          <video controls='controls' className={styles['content-image']} src={item.videoUrl} />
                                        </div>
                                      </div>
                                    );
                                  })
                                }
                              </div>
                            </div>
                          </div>
                        }
                      </div>

                    </div>
                    {
                      data.pic && JSON.parse(data.pic).length > 0 && <div className='row-l'>
                        <div>安装效果:</div>
                        <div className='row-w margin-t5'>
                          {
                            JSON.parse(data.pic).map((item, indexs) => {
                              return (
                                <div key={indexs} className={'margin-r5'}>
                                  <Image src={`${this.baseUrl}/upload/${item}`} className={styles['picBox']} />
                                </div>
                              )
                            })
                          }
                        </div>
                      </div>
                    }
                    {
                      appraiseFee.bonus_order === '是' &&
                      <div className='row-l'>
                        <div>好评图片:</div>
                        <div className='row-w margin-t5'>
                          {
                            data.bonus_pic && JSON.parse(data.bonus_pic).map((item, indexs) => {
                              return (
                                <div key={indexs} className={'margin-r5'}>
                                  <Image src={`${this.baseUrl}/upload/${item}`} className={styles['picBox']} />
                                </div>
                              )
                            })
                          }
                        </div>
                      </div>
                    }
                    {
                      data.service === '安装' &&
                      <div className='margin-t20'>
                        <Table
                          columns={columnsDetails}
                          dataSource={data.serviceDatas}
                          size={'small'}
                          rowKey={record => record.id}
                          bordered
                          pagination={false}
                        />
                      </div>
                    }
                  </div>
                }
                {
                  modalsItem === '修改记录' && <div className='margin-t10'>
                    <Table
                      columns={this.columnModify}
                      dataSource={data.serviceFollows}
                      size={'small'}
                      rowKey={record => record.id + Math.random()}
                      bordered
                      pagination={false}
                    />
                  </div>
                }
                {
                  modalsItem === '售后记录' && <div className='margin-t10'>
                    <Table
                      columns={this.columnService}
                      dataSource={data.serviceAfterSales}
                      size={'small'}
                      rowKey={record => record.id + Math.random()}
                      bordered
                      pagination={false}
                    />
                  </div>
                }
              </div>
            ) : (
              <div>
                <div>
                  <div className='row-l margin-b10'>
                    <div className='margin-r10'>
                      客户姓名:
                    </div>
                    <Input placeholder='请输入客户名字' className='w295' value={data.name} onChange={this.onDataChange.bind(this, 'name')} />
                    <div className='margin-r10 margin-l20'>
                      客户手机:
                    </div>
                    <Input placeholder='请输入客户手机' className='w295' value={data.phone} onChange={this.onDataChange.bind(this, 'phone')} />
                  </div>
                  <div className='row-l margin-b10'>
                  </div>
                  <div className='row-l vertical-lt margin-b10'>
                    <div className='margin-r10'>
                      客户地址:
                    </div>
                    {/* <Input placeholder='' className='w295'/> */}
                    <Regin value={[data.province, data.city, data.district]} onChange={this.onReginChange} />
                    <div className='margin-r10  margin-l20'>
                      详细地址:
                    </div>
                    <Input.TextArea autoComplete="off" rows={4} className='w295' value={data.address} onChange={this.onDataChange.bind(this, 'address')} />
                  </div>
                </div>
                <div className='col vertical-lt w100 margin-b10 margin-t10'>
                  <div className='row-l w100 margin-t10'>
                    <div className={'margin-r10 w90'}>图片备注：</div>
                    <div className='row-w w100'>
                      <Upload
                        type='file'
                        beforeUpload={this.onAddPic}
                        fileList={[]}
                        accept='image/*'
                        multiple={true}
                      >
                        <div className={`row cursor-pointer ${styles['pic-div']}`}>
                          <div className="row h100">
                            <PlusOutlined />
                            <span>
                              新增图片
                            </span>
                          </div>
                        </div>
                      </Upload>
                      {
                        data.pic_remark && typeof data.pic_remark === 'object' &&
                        data.pic_remark.map((item, index) => {
                          return (
                            <div key={index} className={`${styles['content']}`}>
                              <div className={`row ${styles['pic-icon']}`}
                                onClick={this.delSignPic.bind(this, index)}
                              >
                                <DeleteOutlined />
                              </div>
                              <img
                                alt="暂无图片"
                                className={`${styles['pic']}`}
                                src={`${this.baseUrl}/upload/${item}`}
                              />
                              <div className={`row ${styles['content-div']}`}>
                                <EyeOutlined
                                  className={`${styles['pic-icon-E']}`}
                                  onClick={this.preview.bind(this, `${this.baseUrl}/upload/${item}`)}
                                  theme="outlined"
                                  title="预览"
                                />
                              </div>
                            </div>
                          );
                        })
                      }
                    </div>
                  </div>
                  <div className='row-l vertical-lt margin-t10'>
                    <div className='fz16 w90'>视频备注：</div>
                    <div className='col-t vertical-lt w100'>
                      <div className='row-w w900'>
                        {
                          videoList.map((item, index) => {
                            return (
                              <div className={`${styles['video']} ${selectVideo.includes(index) ? styles['video-select'] : ''} none-select`}
                                key={index} onClick={this.selectVideo.bind(this, index)}>
                                {item.name}
                              </div>
                            );
                          })
                        }
                      </div>
                      <div className='row-l vertical-lt w100'>
                        <Upload
                          type='file'
                          beforeUpload={this.uploadVideo}
                          customRequest={() => { }}
                          fileList={[]}
                          multiple={false}
                          maxCount={1}
                        >
                          <div className={`row cursor-pointer ${styles['pic-div']}`}>
                            <div className="row h100">
                              <PlusOutlined />
                              <span>
                                新增视频
                              </span>
                            </div>
                          </div>
                        </Upload>
                        <div className='row-w w100'>
                          {
                            videoPlay.map((item, index) => {
                              return (
                                <div className={`${styles['video-box']} col-t vertical-lt`} key={index}>
                                  <div className={`${styles['content-video']}`}>
                                    <video controls='controls' className={styles['content-image']} src={item.videoUrl} />
                                  </div>
                                  <Popconfirm
                                    title="您确定要删除该视频吗?"
                                    onConfirm={this.delVideo.bind(this, index)}
                                    okText="确认"
                                    cancelText="取消"
                                  >
                                    <Button type='danger' className='w100 margin-t5'>删除视频</Button>
                                  </Popconfirm>
                                </div>
                              );
                            })
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  {type === '安装' && data.category !== '其他' && data.category !== '地板' && data.type !== '二次上门' && data.type !== '售后订单' &&
                    <div className='w100 margin-t20'>
                      <div className='margin-r10'>安装信息:</div>
                      <Spin tip="数据加载中..." spinning={spinningTable}>
                        <Table
                          columns={columns}
                          dataSource={postData}
                          size={'small'}
                          rowKey={record => record.id + Math.random()}
                          bordered
                          pagination={false}
                        />
                      </Spin>
                      <div className={`${styles['top-right-empty']} margin-b20`} onClick={this.onAddData}><PlusCircleOutlined /> 新增产品</div>
                    </div>}
                  <div className='col vertical-lt margin-b10 margin-t10'>
                    <div className='margin-r10 '>
                      订单备注:
                    </div>
                    <Input.TextArea value={data.shop_remark} rows={4} placeholder="填写订单备注信息" style={{ width: 1200 }} onChange={this.onShop_remark.bind(this, 'shop_remark')} />
                  </div>
                  <div className='col vertical-lt margin-b10 margin-t10'>
                    <div className='margin-r10 '>
                      淘宝订单号:
                    </div>
                    <Input.TextArea value={this.state.tbRemark} rows={1} placeholder="填写淘宝订单号" style={{ width: 600 }} onChange={this.onTb_remark} />
                  </div>
                  <div className='col vertical-lt margin-b10 margin-t10'>
                    <div className='margin-r10 '>
                      平台备注(商户):
                    </div>
                    <div>{data.service_shop_remark}</div>
                    {/* <Input.TextArea value={data.service_shop_remark} rows={4} placeholder="填写平台备注(商户)信息" style={{ width: 1200 }} onChange={this.onShop_remark.bind(this, 'service_shop_remark')} /> */}
                  </div>
                  <div className='col back-color0 w100 margin-t10 padding20 vertical-lt'>
                    <div className='row-sb w100'>
                      <div className={styles['top-left-title']}>③ 确认订单费用</div>
                    </div>
                    <div className='row-l margin-t10'>
                      <span className={styles['title-order-cost']}>特殊费用:</span>
                      <div>{data.add_app}</div>
                      {/* <InputNumber
                                                value={data.add_app}
                                                addonAfter="元"
                                                style={{ width: 400 }}
                                                min={0}
                                                onChange={this.onSpecialChange.bind(this)}
                                            /> */}
                      {/* <div className='color5 margin-l10'> (添加后,将被加至总费用金额中)</div> */}
                    </div>
                    <div className='row-l margin-t10'>
                      <span className={styles['title-order-cost']}>特殊费用备注:</span>
                      <div>{data.add_remark}</div>
                      {/* <Input.TextArea value={data.add_remark} rows={4} placeholder="填写相关特殊费用备注信息" style={{ width: 1200 }} onChange={this.onAppRemark.bind(this)} /> */}
                    </div>
                    <div className='row-l margin-t10'>
                      <span className={styles['title-order-cost']}>应付金额:</span>
                      <div>
                        <span className='color2 fz20'>
                          {/* {type === '安装' ? this.getAllPrices() + data.add_app : this.state.price_app + data.add_app} */}
                          {type === '安装' && data.category !== '地板'
                            && data.category !== '其他'
                            && data.type !== '二次上门'
                            && data.type !== '售后订单' ?
                            this.getAllPrices() + data.add_app :
                            this.state.price_app + data.add_app
                          }
                        </span>元
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )
          }
        </Spin>
        <Modal
          title={'修改窗帘名称'}
          visible={visibleName}
          width={700}
          onCancel={this.onCancelName}
          onOk={this.onOkName}
          okText='确定'
          cancelText='取消'
        >
          <div>
            <Input className='margin-b10' value={roomModalValue} onChange={this.onModalValueChange} />
            <div className='row-w'>
              {
                roomType.map((item, index) => {
                  return (
                    <div key={index} className={styles['room_type_div']} onClick={this.onRoomModalValue.bind(this, item)}>{item}</div>
                  )
                })
              }
            </div>
          </div>
        </Modal>
      </Modal>
    );
  }
  getVideoTemplate = async () => {
    let res = await getShopVideos(this.shopId);
    if (res.status === 200) {
      let videoList = res.data ? JSON.parse(res.data) : [];
      this.setState({
        videoList
      });
    }
  }
  selectVideo = (e) => {
    let selectVideo = [...this.state.selectVideo];
    if (selectVideo.includes(e)) {
      selectVideo = selectVideo.filter(r => r !== e);
    } else {
      selectVideo.push(e);
    }
    this.setState({
      selectVideo
    });
  }
  //图片备注
  onAddPic = async (file, fileList) => {
    let data = _.cloneDeep(this.state.data)
    if (fileList[fileList.length - 1] !== file) {
      return;
    }
    let leng = data.pic_remark.length;
    for (let i = 0; i < fileList.length; i++) {
      let name = await ossUpload(fileList[i], null);
      data.pic_remark[leng + i] = name;
    }
    this.setState({
      data
    })
  }
  delSignPic = (e) => {
    let data = _.cloneDeep(this.state.data)
    data.pic_remark.splice(e, 1);
    this.setState({
      data,
    });
  }
  preview = (url) => {
    let features = "height=500, width=800, top=100, left=100, toolbar=no, menubar=no,scrollbars=no,resizable=no, location=no, status=no";
    window.open(url, "newW", features);
  }
  //上传视频
  uploadVideo = async (file, fileList) => {
    if (fileList[fileList.length - 1] !== file) {
      return;
    }
    if (fileList.length > 1) {
      message.warn('只能上传单个视频');
      return;
    }
    let decimal = (new Date()).valueOf();
    let name = `${convert.toString36(decimal)}`.replace(/:/g, '-');
    let res = await getVideoInfo(name, file.name);
    let uploadAddress = JSON.parse(decodeURI(atob(res.data.uploadAddress)));
    let uploadInfos = {
      video: res.data.videoId,
      Endpoint: uploadAddress.Endpoint,
      Bucket: uploadAddress.Bucket,
      object: null,
    };
    let that = this;
    this.uploader = new AliyunUpload.Vod({
      userId: '112',
      region: 'cn-shanghai',
      partSize: 1048576,
      parallel: 5,
      retryCount: 3,
      retryDuration: 2,
      'onUploadstarted': async (uploadInfo) => {
        this.uploader.setUploadAuthAndAddress(uploadInfo, res.data.uploadAuth, res.data.uploadAddress, res.data.videoId);
      },
      'onUploadEnd': async function (uploadInfo) {
        let rsp = await getVideoSub(res.data.videoId);
        if (rsp.status === 204) {
          that.getVideoUrl(res.data.videoId);
        } else {
          message.error(rsp.data);
        }
      }
    });
    this.uploader.addFile(file, uploadInfos.Endpoint, uploadInfos.Bucket, null, '{"Vod":{}}');
    this.uploader.startUpload();
  }
  getVideoUrl = async (e) => {
    let res = await getVideoPlay(e);
    if (res.status === 200) {
      let videoPlay = [...this.state.videoPlay]
      videoPlay.push({
        name: '新增视频',
        videoId: e,
        videoUrl: res.data.playURL,
        isVideo: true,
      });
      this.setState({
        videoPlay: videoPlay
      })
    }
  }
  delVideo = (index) => {
    let videoPlay = [...this.state.videoPlay]
    videoPlay.splice(index, 1)
    this.setState({
      videoPlay: videoPlay
    })
  }

  //窗帘名称
  getRoomModal = (index, name) => {
    this.setState({
      visibleName: true,
      modalIndex: index,
      roomModalValue: name
    })
  }
  onRoomModalValue = (e) => {
    this.setState({
      roomModalValue: e
    })
  }
  onModalValueChange = (e) => {
    this.setState({
      roomModalValue: e.target.value
    })
  }
  onOkName = () => {
    let { modalIndex, roomModalValue } = this.state
    this.getInputVaule('room', modalIndex, roomModalValue)
    this.onCancelName()
  }
  //关闭modal
  onCancelName = () => {
    this.setState({
      visibleName: false,
      modalIndex: 0,
    })
  }

  //订单详情
  //切换modals
  onModalsClick = (e) => {
    this.setState({
      modalsItem: e
    })
  }


  //修改订单
  //省市区
  onReginChange = (e) => {
    let data = { ...this.state.data }
    data.province = e[0];
    data.city = e[1];
    data.district = e[2];
    this.setState({
      data: data
    })
  }
  //输入框
  onDataChange = (type, e) => {
    let data = { ...this.state.data }
    data[type] = e.target.value
    this.setState({
      data: data
    })
  }
  //特殊备注
  onAppRemark = (e) => {
    let data = { ...this.state.data };
    data.add_remark = e.target.value;
    this.setState({
      data: data
    })
  }
  //订单备注
  onShop_remark = (type, e) => {
    let data = { ...this.state.data };
    data[type] = e.target.value;
    this.setState({
      data: data
    })
  }
  //淘宝订单号
  onTb_remark = (e) => {
    let tbRemark = e.target.value;
    tbRemark = tbRemark.replace(/[^\w\u4e00-\u9fa5]/g, '')
    this.setState({
      tbRemark
    });
  }
  //特殊费用
  onSpecialChange = (e) => {
    let data = { ...this.state.data };
    data.add_app = e
    this.setState({
      data: data
    })
  }
  //算价
  getPrices = async (index) => {
    this.setState({
      spinningTable: true,
    })
    const { type, optionsRadio, detailID, orderPrice, data, postData, orderPrice_oms, serviceItemG } = this.state;
    let num = [];
    if (type !== '测量') {
      let newOptionsRadio = optionsRadio[index];
      for (let i in newOptionsRadio) {
        num.push(Number(newOptionsRadio[i].id))
      }
      if (this.state.data.category === '其他' || this.state.data.category === '地板') {
        num.push(measureID);
      } else {
        num.push(detailID[index]);
      }
    }
    let itemid = num.join('|');
    let rsp = await getPrice(itemid, data.id, this.shopId);
    if (rsp.status !== 200) {
      message.error(res.data)
      return;
    };
    if (this.state.types === '窗帘') {
      serviceItemG[index] = rsp.data.serviceItems;
    }
    orderPrice[index] = rsp.data.price_app;
    orderPrice_oms[index] = rsp.data.price_oms;
    this.setState({
      orderPrice: orderPrice,
      orderPrice_oms: orderPrice_oms,
      serviceItemG: serviceItemG,
      spinningTable: false,
    });
  }
  //删除
  ondelData = (index) => {
    let postData = [...this.state.postData];
    let orderPrice = [...this.state.orderPrice];
    let optionsRadio = [...this.state.optionsRadio];
    let groupType = [...this.state.groupType];
    let detailType = [...this.state.detailType];
    let orderPrice_oms = [...this.state.orderPrice_oms];
    let serviceItemG = [...this.state.serviceItemG];
    postData.splice(index, 1);
    orderPrice.splice(index, 1);
    optionsRadio.splice(index, 1);
    detailType.splice(index, 1);
    groupType.splice(index, 1);
    orderPrice_oms.splice(index, 1);
    serviceItemG.splice(index, 1);
    this.setState({
      postData: postData,
      orderPrice: orderPrice,
      optionsRadio: optionsRadio,
      serviceItemG: serviceItemG,
      groupType: groupType,
      detailType: detailType,
      orderPrice_oms: orderPrice_oms
    })

  }
  //新增
  onAddData = () => {
    let postData = [...this.state.postData];
    const { groupType, detailType, datas, detailID } = this.state;
    let newData = { ...serviceDatas };
    // newData.sort = postData.length;
    newData.order = this.state.data.id
    postData.push(newData);
    groupType.push(datas.grid[0].group)
    detailType.push(datas.grid[0].detail[0].name)
    detailID.push(datas.grid[0].detail[0].id)
    this.setState({
      postData: postData,
      groupType: groupType,
      detailType: detailType,
      detailID: detailID,
    })
    this.getOptionsRadio(postData, this.state.datas.grid[0].detail[0].add, postData.length - 1)
  }
  //类型切换
  onClickDetail = (type, id, index, e) => {
    let detail = this.state.datas.grid.filter(r => r.group === this.state.groupType[index])[0].detail;
    let detailAdd = detail.filter(r => r.name === type)[0].add;
    let postData = [...this.state.postData];

    postData[index].prop3 = JSON.stringify({ id: id, name: type });
    let detailType = [...this.state.detailType];
    let detailID = [...this.state.detailID];
    detailType[index] = type;
    detailID[index] = detail.filter(r => r.name === type)[0].id;
    this.setState({
      detailType: detailType,
      detailID: detailID,
    }, async () => this.getPrices(index));
    this.getOptionsRadio(postData, detailAdd, index)
  }
  //品类切换
  onClickGrid = (type, index) => {
    let postData = [...this.state.postData];
    let detail = this.state.datas.grid.filter(r => r.group === type)[0].detail;
    let detailAdd = detail[0].add;
    postData[index].prop1 = type;
    let groupType = [...this.state.groupType];
    groupType[index] = type
    let detailType = [...this.state.detailType];
    let detailID = [...this.state.detailID];
    detailType[index] = detail[0].name;
    detailID[index] = detail[0].id;
    this.setState({
      groupType: groupType,
      detailType: detailType,
      detailID: detailID,
    }, async () => this.getPrices(index));
    this.getOptionsRadio(postData, detailAdd, index)
  }
  //品类数据
  getOptionsRadio = (postData, detailAdd, index) => {
    let optionsRadio = [...this.state.optionsRadio];
    postData.forEach((pitem, pindex) => {
      let newOptionsRadio = {};
      detailAdd.forEach(ditem => {
        newOptionsRadio[ditem.group] = { id: ditem.options[0].id, name: ditem.options[0].name };
      });
      optionsRadio.splice(index, 1, newOptionsRadio)
    })
    this.setState({
      optionsRadio: optionsRadio,
    }, async () => this.changePostData(postData, index))
  }
  //table数据
  changePostData = (data, index) => {
    let detail = this.state.datas.grid.filter(r => r.group === this.state.groupType[index])[0].detail;
    let detailAdd = detail.filter(r => r.name === this.state.detailType[index])[0].add;
    let prop3ID = detail.filter(r => r.name === this.state.detailType[index])[0].id
    const { groupType, detailType } = this.state;
    data[index].prop1 = groupType[index];
    data[index].prop3 = JSON.stringify({ id: prop3ID, name: detailType[index] });
    data[index].prop4 = '';
    data[index].prop5 = '';
    data[index].prop6 = '';
    data[index].prop7 = '';
    data[index].prop8 = '';
    data[index].prop9 = '';
    detailAdd.forEach(item => {
      switch (item.group) {
        case this.getCaseType(this.state.data.category, 'prop4'):
          data[index].prop4 = JSON.stringify({ id: item.options[0].id, name: item.options[0].name });
          break;
        case this.getCaseType(this.state.data.category, 'prop5'):
          data[index].prop5 = JSON.stringify({ id: item.options[0].id, name: item.options[0].name });
          break;
        case this.getCaseType(this.state.data.category, 'prop6'):
          data[index].prop6 = JSON.stringify({ id: item.options[0].id, name: item.options[0].name });
          break;
        case this.getCaseType(this.state.data.category, 'prop7'):
          data[index].prop7 = JSON.stringify({ id: item.options[0].id, name: item.options[0].name });
          break;
        case this.getCaseType(this.state.data.category, 'prop8'):
          data[index].prop8 = JSON.stringify({ id: item.options[0].id, name: item.options[0].name });
          break;
        case this.getCaseType(this.state.data.category, 'prop9'):
          data[index].prop9 = JSON.stringify({ id: item.options[0].id, name: item.options[0].name });
          break;
        default:
          break;
      }
    })
    this.setState({
      postData: data,
    }, async () => this.getPrices(index))
  }
  //table品类
  tableType = (itemType, index) => {
    if (JSON.stringify(this.state.datas) === '{}') {
      return;
    }
    if (!this.state.optionsRadio[index]) {
      return;
    }
    let detail = this.state.datas.grid.filter(r => r.group === this.state.groupType[index])[0].detail;
    let detailAdd = detail.filter(r => r.name === this.state.detailType[index])[0].add;
    let options = detailAdd.filter(r => r.group === itemType);
    let optionsRadio = this.state.optionsRadio[index];
    return (
      <div className='col'>
        {
          options.length === 0 ? <div className={styles['options0']}></div> : <Radio.Group value={optionsRadio[itemType].id} className='row-w'>
            {
              options[0].options.map((oItem, oIndex) => {
                return (
                  <Radio value={oItem.id} key={oIndex} onChange={this.onChangeRadio.bind(this, oItem.name, oItem.id, itemType, index)}>{oItem.name}</Radio>
                )
              })
            }
          </Radio.Group>
        }
      </div>
    )
  }
  //table品类切换
  onChangeRadio = (name, id, type, index, e) => {
    let optionsRadio = [...this.state.optionsRadio];
    let postData = [...this.state.postData];
    optionsRadio[index][type] = { id, name };
    switch (type) {
      case this.getCaseType(this.state.data.category, 'prop4'):
        postData[index].prop4 = JSON.stringify({ id, name });
        break;
      case this.getCaseType(this.state.data.category, 'prop5'):
        postData[index].prop5 = JSON.stringify({ id, name });
        break;
      case this.getCaseType(this.state.data.category, 'prop6'):
        postData[index].prop6 = JSON.stringify({ id, name });
        break;
      case this.getCaseType(this.state.data.category, 'prop7'):
        postData[index].prop7 = JSON.stringify({ id, name });
        break;
      case this.getCaseType(this.state.data.category, 'prop8'):
        postData[index].prop8 = JSON.stringify({ id, name });
        break;
      case this.getCaseType(this.state.data.category, 'prop9'):
        postData[index].prop9 = JSON.stringify({ id, name });
        break;
      default:
        break;
    }
    this.setState({
      optionsRadio: optionsRadio,
      postData: postData
    }, async () => this.getPrices(index))
  }
  //输入框数据
  getInputVaule = (type, index, e) => {
    let value;
    if (type === 'room') {
      value = e
    } else {
      value = isNaN(Number(e.target.value)) ? 1 : e.target.value
      if (e.target.value <= 0) {
        value = 1
      }
    }
    let postData = [...this.state.postData];
    postData[index][type] = value + '';
    this.setState({
      postData: postData
    })
  }
  //打开modal
  onShowModal = (data, type) => {
    this.setState({
      visible: true,
      type: data.service,
      dataIndex: data,
      id: data.id,
      modalType: type,
    })
    this.getData(data.id, data.category, data.service, type);
  }
  //获取数据
  getData = async (id, e, type, modalType) => {
    this.setState({
      spinningTable: true,
      spinning: true,
    })
    let tbRemark = '';
    let appraiseFee = {
      bonus_order: '否',
      shop: 0,
    };
    let res = await getOrder(id)
    if (res.status !== 200) {
      message.error(res.data);
      this.onCancel();
      return;
    }

    let videoList = [];
    // let result = await getShopVideos(this.shopId);
    // if (result.status === 200) {
    //   videoList = result.data ? JSON.parse(result.data) : [];
    // }
    // let sumName = res.data.name.split('DD');
    tbRemark = res.data.platform;
    res.data.name = res.data.name.split('DD')[0];

    res.data.pic_remark = res.data.pic_remark && JSON.parse(res.data.pic_remark) ? JSON.parse(res.data.pic_remark) : [];
    appraiseFee.bonus_order = res.data.bonus_order;
    if (res.data.sFinances) {
      res.data.sFinances.map(item => {
        if (item.type === '好评') {
          appraiseFee.shop += item.price
        }
      })
    }
    appraiseFee.shop = Math.abs(appraiseFee.shop);
    let selectVideo = [];
    let videoPlay = [];
    if (res.data.vod_remark && res.data.vod_remark.includes('[') && res.data.vod_remark.includes(']')) {
      res.data.vod_remark = JSON.parse(res.data.vod_remark);
    } else {
      res.data.vod_remark = [];
    }
    if (res.data.vod_remark.length > 0) {
      res.data.vod_remark.map(item => {
        // let exit = videoList.findIndex(r => r.name === item.name && r.videoId === item.videoId);
        // if (exit > -1) {
        //   selectVideo.push(exit);
        // }
        // if (item.isVideo) {
        videoPlay.push(item);
        // }
      })
      let that = this;
      videoPlay.map((item, index) => {
        (async () => {
          let local = index;
          let rsp = await getVideoPlay(item.videoId);
          if (rsp.status === 200) {
            videoPlay[local].videoUrl = rsp.data.playURL;
            that.setState({
              videoPlay
            });
          }
        })()
      })
    }
    let customTime = '';
    let customRemark = '';
    let remarkSum = res.data.shop_remark.split('✱');
    if (remarkSum.length === 3) {
      customTime = remarkSum[1] ? remarkSum[1] : '';
      customRemark = remarkSum[2] ? remarkSum[2] : '';
    }
    res.data.shop_remark = remarkSum[0]
    res.data.serviceFollows = res.data.serviceFollows.filter(r => r.type !== '跟进')
    this.setState({
      data: res.data,
      shopPrice: res.data.shopPrice,
      tbRemark, customTime, customRemark,
      videoList, selectVideo, videoPlay, appraiseFee,
    })
    this.getColumns(res.data.category);

    if (modalType !== '订单详情') {
      let rsp = await getCategory(e, this.shopId);
      if (rsp.status !== 200) {
        message.error(res.data);
        this.onCancel();
        return;
      };

      let price_app = 0;
      if (type === '测量') {
        res.data.serviceItems.forEach(item => {
          price_app += item.price_app
        })
        let dataIndex = 0;
        rsp.data.map((ditem, dindex) => {
          if (ditem.type.includes('测量')) {
            dataIndex = dindex
          }
        })
        this.setState({
          datas: rsp.data[dataIndex],
          orderPrice: [res.data.price_app],
          price_app: price_app,
          data: res.data,
          spinningTable: false,
          spinning: false,
        })
      } else {
        let groupType = [];
        let detailType = [];
        let detailID = [];
        let orderPrice = [];
        let orderPrice_oms = [];
        res.data.serviceItems.forEach(item => {
          price_app += item.price_app
        })
        let dataIndex = 0;
        rsp.data.map((ditem, dindex) => {
          if (ditem.type.includes('安装')) {
            dataIndex = dindex
          }
        })
        if (res.data.category === '地板' || res.data.category === '其他') {
          price_app = res.data.price_app
        }
        res.data.serviceDatas.forEach(async (item, index) => {
          groupType.push(item.prop1);
          if (res.data.category !== '地板') {
            detailID.push(JSON.parse(item.prop3).id);
            detailType.push(JSON.parse(item.prop3).name);
          }
          if (res.data.category === '窗帘') {
            let ids = [];
            ids.push(JSON.parse(item.prop3).id)
            if (item.prop4) {
              ids.push(JSON.parse(item.prop4).id)
            }
            if (item.prop5) {
              ids.push(JSON.parse(item.prop5).id)
            }
            if (item.prop6) {
              ids.push(JSON.parse(item.prop6).id)
            }
            if (item.prop7) {
              ids.push(JSON.parse(item.prop7).id)
            }
            if (item.prop8) {
              ids.push(JSON.parse(item.prop8).id)
            }
            if (item.prop9) {
              ids.push(JSON.parse(item.prop9).id)
            }
            let itemid = ids.join('|');
            let result = await getPrice(itemid, res.data.work, res.data.shop);
            if (result.status !== 200) {
              message.error(result.data)
            }
            let local = index;
            orderPrice[local] = result.data.price_app;
            orderPrice_oms[local] = result.data.price_oms;
            this.setState({
              orderPrice, orderPrice_oms
            })
            // orderPrice.push(item.prop10);
            // orderPrice_oms.push(item.prop11)
          } else if (res.data.category === '墙布墙纸' || res.data.category === '晾衣架') {
            orderPrice.push(item.prop8);
            orderPrice_oms.push(item.prop9)
          }
        })
        this.setState({
          datas: rsp.data[dataIndex],
          groupType: groupType,
          detailType: detailType,
          detailID: detailID,
          // detailAdd: rsp.data[1].grid[0].detail[0].add,
          postData: res.data.serviceDatas,
          orderPrice: orderPrice,
          orderPrice_oms: orderPrice_oms,
          price_app: price_app,
          data: res.data,
          spinningTable: false,
          spinning: false,
          // specialPrice: res.data.add_app,
        }, async () => this.getOptionsRadios());
      }
    } else {
      this.setState({
        data: res.data,
        spinning: false,
      })
    }
  }

  //获取表格
  getColumns = (type) => {
    let columns = [];
    let columnsDetails = [];
    if (type === '窗帘') {
      columns = [
        {
          title: '房间名称',
          align: 'center',
          width: 100,
          render: (text, record, index) => {
            return (
              <div className={styles['room_div_style']} onClick={this.getRoomModal.bind(this, index, record.room)}>{record.room}</div>
              // <Input onChange={this.getInputVaule.bind(this, 'room', index)} />
            )
          }
        },
        {
          title: '长度（米）',
          align: 'center',
          width: 80,
          render: (text, record, index) => {
            return (
              // <InputNumber mix={0} defaultValue={record.width} onChange={this.getInputVaule.bind(this, 'width', index)} />
              <InputNumber mix={0} defaultValue={record.width} onBlur={this.getInputVaule.bind(this, 'width', index)} />
            )
          }
        },
        {
          title: '品类',
          align: 'center',
          width: 300,
          render: (text, record, index) => {
            if (JSON.stringify(this.state.datas) === '{}') {
              return;
            }
            let grid = this.state.datas.grid;
            let detail = this.state.datas.grid.filter(r => r.group === this.state.groupType[index])[0].detail;
            return (
              <div className='row-sb'>
                <div className='col'>
                  {
                    grid.map((ditem, dindex) => {
                      return (
                        <div
                          className={`${styles['group-div']} ${this.state.groupType[index] === ditem.group && styles['group-div-color']}`}
                          key={dindex}
                          onClick={this.onClickGrid.bind(this, ditem.group, index)}
                        >
                          {ditem.group}
                        </div>
                      )
                    })
                  }
                </div>
                <div className={styles['vertical-line']}></div>
                <Radio.Group value={this.state.detailType[index]} className='row-w'>
                  {
                    detail.map((ditem, dindex) => {
                      return (
                        <Radio value={ditem.name} key={dindex} onClick={this.onClickDetail.bind(this, ditem.name, ditem.id, index)}>{ditem.name}</Radio>
                      )
                    })
                  }
                </Radio.Group>
              </div>
            )
          }
        },
        {
          title: '层数',
          align: 'center',
          width: 150,
          render: (text, record, index) => {
            return (
              this.tableType('层数', index)
            )
          }
        },
        {
          title: '四角钩',
          align: 'center',
          width: 80,
          render: (text, record, index) => {
            return (
              this.tableType('四角钩', index)
            )
          }
        },
        {
          title: '墙钩',
          align: 'center',
          width: 80,
          render: (text, record, index) => {
            return (
              this.tableType('墙钩', index)
            )
          }
        },
        {
          title: '窗幔',
          align: 'center',
          width: 150,
          render: (text, record, index) => {
            return (
              this.tableType('窗幔', index)
            )
          }
        },
        {
          title: '超高',
          align: 'center',
          width: 80,
          render: (text, record, index) => {
            return (
              this.tableType('超高', index)
            )
          }
        },
        {
          title: '其他',
          align: 'center',
          width: 200,
          render: (text, record, index) => {
            return (
              this.tableType('其他', index)
            )
          }
        },
        {
          title: '数量',
          align: 'center',
          width: 80,
          render: (text, record, index) => {
            return (
              <InputNumber min={1} defaultValue={record.prop2} onBlur={this.getInputVaule.bind(this, 'prop2', index)} />
            )
          }
        },
        {
          title: '费用',
          align: 'center',
          width: 80,
          render: (text, record, index) => {
            return (
              <div>{this.getProp2Price(record.prop2, index)}</div>
            )
          }
        },
        {
          title: '操作',
          align: 'center',
          width: 80,
          render: (text, record, index) => {
            return (
              <div><DeleteOutlined className={styles['delete-style']} onClick={this.ondelData.bind(this, index)} /></div>
            )
          }
        },
      ];
      columnsDetails = [
        {
          title: '名称',
          align: 'center',
          dataIndex: 'room',
          width: 100,
        },
        {
          title: '长度（米）',
          align: 'center',
          dataIndex: 'width',
          width: 80,
        },
        {
          title: '品类',
          align: 'center',
          dataIndex: 'prop1',
          width: 80,
        },
        {
          title: '类型',
          align: 'center',
          width: 80,
          render: (text, record, index) => {
            return (
              <div>{record.prop3 !== '' && JSON.parse(record.prop3).name}</div>
            )
          }
        },
        {
          title: '层数',
          align: 'center',
          width: 80,
          render: (text, record, index) => {
            return (
              <div>{record.prop4 !== '' && JSON.parse(record.prop4).name}</div>
            )
          }
        },
        {
          title: '四角钩',
          align: 'center',
          width: 80,
          render: (text, record, index) => {
            return (
              <div>{record.prop5 !== '' && JSON.parse(record.prop5).name}</div>
            )
          }
        },
        {
          title: '墙钩',
          align: 'center',
          width: 80,
          render: (text, record, index) => {
            return (
              <div>{record.prop6 !== '' && JSON.parse(record.prop6).name}</div>
            )
          }
        },
        {
          title: '窗幔',
          align: 'center',
          width: 80,
          render: (text, record, index) => {
            return (
              <div>{record.prop7 !== '' && JSON.parse(record.prop7).name}</div>
            )
          }
        },
        {
          title: '超高',
          align: 'center',
          width: 80,
          render: (text, record, index) => {
            return (
              <div>{record.prop8 !== '' && JSON.parse(record.prop8).name}</div>
            )
          }
        },
        {
          title: '其他',
          align: 'center',
          width: 80,
          render: (text, record, index) => {
            return (
              <div>{record.prop9 !== '' && JSON.parse(record.prop9).name}</div>
            )
          }
        },
        {
          title: '数量',
          align: 'center',
          width: 80,
          render: (text, record, index) => {
            return (
              <div>{record.prop2}</div>
            )
          }
        },
        {
          title: '费用',
          align: 'center',
          width: 80,
          render: (text, record, index) => {
            return (
              <div>{record.prop10}</div>
            )
          }
        },
      ];
    } else if (type === '墙布墙纸') {
      columns = [
        {
          title: '房间名称',
          align: 'center',
          width: 100,
          render: (text, record, index) => {
            return (
              <div className={styles['room_div_style']} onClick={this.getRoomModal.bind(this, index, record.room)}>{record.room}</div>
              // <Input onChange={this.getInputVaule.bind(this, 'room', index)} />
            )
          }
        },
        {
          title: '材质',
          align: 'center',
          width: 300,
          render: (text, record, index) => {
            if (JSON.stringify(this.state.datas) === '{}') {
              return;
            }
            let grid = this.state.datas.grid;
            let detail = this.state.datas.grid.filter(r => r.group === this.state.groupType[index])[0].detail;
            return (
              <div className='row-sb'>
                <div className='col'>
                  {
                    grid.map((ditem, dindex) => {
                      return (
                        <div
                          className={`${styles['group-div']} ${this.state.groupType[index] === ditem.group && styles['group-div-color']}`}
                          key={dindex}
                          onClick={this.onClickGrid.bind(this, ditem.group, index)}
                        >
                          {ditem.group}
                        </div>
                      )
                    })
                  }
                </div>
                <div className={styles['vertical-line']}></div>
                <Radio.Group value={this.state.detailType[index]} className='row-w'>
                  {
                    detail.map((ditem, dindex) => {
                      return (
                        <Radio value={ditem.name} key={dindex} onClick={this.onClickDetail.bind(this, ditem.name, ditem.id, index)}>{ditem.name}</Radio>
                      )
                    })
                  }
                </Radio.Group>
              </div>
            )
          }
        },
        {
          title: '规格',
          align: 'center',
          width: 150,
          render: (text, record, index) => {
            return (
              this.tableType('规格', index)
            )
          }
        },
        {
          title: '施工方式',
          align: 'center',
          width: 150,
          render: (text, record, index) => {
            return (
              this.tableType('施工方式', index)
            )
          }
        },
        {
          title: '施工工艺',
          align: 'center',
          width: 80,
          render: (text, record, index) => {
            return (
              this.tableType('施工工艺', index)
            )
          }
        },
        {
          title: '拆旧',
          align: 'center',
          width: 200,
          render: (text, record, index) => {
            return (
              this.tableType('拆旧', index)
            )
          }
        },
        {
          title: '数量',
          align: 'center',
          width: 80,
          render: (text, record, index) => {
            return (
              <InputNumber min={1} defaultValue={record.prop2} onBlur={this.getInputVaule.bind(this, 'prop2', index)} />
            )
          }
        },
        {
          title: '费用',
          align: 'center',
          width: 80,
          render: (text, record, index) => {
            return (
              <div>{this.getProp2Price(record.prop2, index)}</div>
            )
          }
        },
        {
          title: '操作',
          align: 'center',
          width: 80,
          render: (text, record, index) => {
            return (
              <div><DeleteOutlined className={styles['delete-style']} onClick={this.ondelData.bind(this, index)} /></div>
            )
          }
        },
      ];
      columnsDetails = [
        {
          title: '名称',
          align: 'center',
          dataIndex: 'room',
          width: 100,
        },
        {
          title: '品类',
          align: 'center',
          dataIndex: 'prop1',
          width: 80,
        },
        {
          title: '材质',
          align: 'center',
          width: 80,
          render: (text, record, index) => {
            return (
              <div>{record.prop3 !== '' && JSON.parse(record.prop3).name}</div>
            )
          }
        },
        {
          title: '规格',
          align: 'center',
          width: 80,
          render: (text, record, index) => {
            return (
              <div>{record.prop4 !== '' && JSON.parse(record.prop4).name}</div>
            )
          }
        },
        {
          title: '施工方式',
          align: 'center',
          width: 80,
          render: (text, record, index) => {
            return (
              <div>{record.prop5 !== '' && JSON.parse(record.prop5).name}</div>
            )
          }
        },
        {
          title: '施工工艺',
          align: 'center',
          width: 80,
          render: (text, record, index) => {
            return (
              <div>{record.prop6 !== '' && JSON.parse(record.prop6).name}</div>
            )
          }
        },
        {
          title: '拆旧',
          align: 'center',
          width: 80,
          render: (text, record, index) => {
            return (
              <div>{record.prop7 !== '' && JSON.parse(record.prop7).name}</div>
            )
          }
        },
        {
          title: '数量',
          align: 'center',
          width: 80,
          render: (text, record, index) => {
            return (
              <div>{record.prop2}</div>
            )
          }
        },
        {
          title: '费用',
          align: 'center',
          width: 80,
          render: (text, record, index) => {
            return (
              <div>{record.prop8}</div>
            )
          }
        },
      ]
    } else if (type === '晾衣架') {
      columns = [
        {
          title: '房间名称',
          align: 'center',
          width: 100,
          render: (text, record, index) => {
            return (
              <div className={styles['room_div_style']} onClick={this.getRoomModal.bind(this, index, record.room)}>{record.room}</div>
              // <Input onChange={this.getInputVaule.bind(this, 'room', index)} />
            )
          }
        },
        {
          title: '类型',
          align: 'center',
          width: 300,
          render: (text, record, index) => {
            if (JSON.stringify(this.state.datas) === '{}') {
              return;
            }
            if (!this.state.datas.type.includes('安装')) {
              return;
            }
            let grid = this.state.datas.grid;
            let detail = this.state.datas.grid.filter(r => r.group === this.state.groupType[index])[0].detail;
            return (
              <div className='row-sb'>
                <div className='col'>
                  {
                    grid.map((ditem, dindex) => {
                      return (
                        <div
                          className={`${styles['group-div']} ${this.state.groupType[index] === ditem.group && styles['group-div-color']}`}
                          key={dindex}
                          onClick={this.onClickGrid.bind(this, ditem.group, index)}
                        >
                          {ditem.group}
                        </div>
                      )
                    })
                  }
                </div>
                <div className={styles['vertical-line']}></div>
                <Radio.Group value={this.state.detailType[index]} className='row-w'>
                  {
                    detail.map((ditem, dindex) => {
                      return (
                        <Radio value={ditem.name} key={dindex} onClick={this.onClickDetail.bind(this, ditem.name, ditem.id, index)}>{ditem.name}</Radio>
                      )
                    })
                  }
                </Radio.Group>
              </div>
            )
          }
        },
        {
          title: '驱动方式',
          align: 'center',
          width: 150,
          render: (text, record, index) => {
            return (
              this.tableType('驱动方式', index)
            )
          }
        },
        {
          title: '安装面',
          align: 'center',
          width: 150,
          render: (text, record, index) => {
            return (
              this.tableType('安装面', index)
            )
          }
        },
        {
          title: '超高',
          align: 'center',
          width: 80,
          render: (text, record, index) => {
            return (
              this.tableType('超高', index)
            )
          }
        },
        {
          title: '拆旧',
          align: 'center',
          width: 200,
          render: (text, record, index) => {
            return (
              this.tableType('拆旧', index)
            )
          }
        },
        {
          title: '数量',
          align: 'center',
          width: 80,
          render: (text, record, index) => {
            return (
              <InputNumber min={1} defaultValue={record.prop2} onBlur={this.getInputVaule.bind(this, 'prop2', index)} />
            )
          }
        },
        {
          title: '费用',
          align: 'center',
          width: 80,
          render: (text, record, index) => {
            return (
              <div>{this.getProp2Price(record.prop2, index)}</div>
            )
          }
        },
        {
          title: '操作',
          align: 'center',
          width: 80,
          render: (text, record, index) => {
            return (
              <div><DeleteOutlined className={styles['delete-style']} onClick={this.ondelData.bind(this, index)} /></div>
            )
          }
        },
      ];
      columnsDetails = [
        {
          title: '名称',
          align: 'center',
          dataIndex: 'room',
          width: 100,
        },
        {
          title: '品类',
          align: 'center',
          dataIndex: 'prop1',
          width: 80,
        },
        {
          title: '类型',
          align: 'center',
          width: 80,
          render: (text, record, index) => {
            return (
              <div>{record.prop3 !== '' && JSON.parse(record.prop3).name}</div>
            )
          }
        },
        {
          title: '驱动方式',
          align: 'center',
          width: 80,
          render: (text, record, index) => {
            return (
              <div>{record.prop4 !== '' && JSON.parse(record.prop4).name}</div>
            )
          }
        },
        {
          title: '安装面',
          align: 'center',
          width: 80,
          render: (text, record, index) => {
            return (
              <div>{record.prop5 !== '' && JSON.parse(record.prop5).name}</div>
            )
          }
        },
        {
          title: '超高',
          align: 'center',
          width: 80,
          render: (text, record, index) => {
            return (
              <div>{record.prop6 !== '' && JSON.parse(record.prop8).name}</div>
            )
          }
        },
        {
          title: '拆旧',
          align: 'center',
          width: 80,
          render: (text, record, index) => {
            return (
              <div>{record.prop7 !== '' && JSON.parse(record.prop7).name}</div>
            )
          }
        },
        {
          title: '数量',
          align: 'center',
          width: 80,
          render: (text, record, index) => {
            return (
              <div>{record.prop2}</div>
            )
          }
        },
        {
          title: '费用',
          align: 'center',
          width: 80,
          render: (text, record, index) => {
            return (
              <div>{record.prop8}</div>
            )
          }
        },
      ]
    }
    this.setState({
      columns: columns,
      columnsDetails: columnsDetails,
    })
  }

  getProp2Price = (prop2, index) => {
    let { orderPrice, postData, types } = this.state;
    let price = 0;
    let multiple = this.getTypeProp2(postData[index].prop1, postData[index].width);
    let grapplePrice = this.getGrapplePrice(index, multiple);
    price = orderPrice[index] * Number(prop2) * multiple - grapplePrice.price_app * Number(prop2);
    return price;
  }
  getGrapplePrice = (index, multiple) => {
    let { optionsRadio, serviceItemG } = this.state;
    if (optionsRadio.length === 0) {
      return { price_app: 0, price_oms: 0 };
    }
    if (multiple === 1) {
      return { price_app: 0, price_oms: 0 };
    }
    if (!optionsRadio[index]['墙钩']) {
      return { price_app: 0, price_oms: 0 };
    }
    if (optionsRadio[index]['墙钩'].name === '无') {
      return { price_app: 0, price_oms: 0 };
    }
    let newOptionsRadio = optionsRadio[index];
    let newServiceItemG = serviceItemG[index];
    let data = newServiceItemG.filter(r => r.service === newOptionsRadio['墙钩'].id)[0];
    if (!data) { return { price_app: 0, price_oms: 0 }; }
    let price_app = data.price_app;
    let price_oms = data.price_oms;
    return {
      price_app: price_app * multiple - price_app,
      price_oms: price_oms * multiple - price_oms,
    }
  }
  getTypeProp2 = (type, width) => {
    let quantity = 0;
    let widths = Number(width);
    let prop2Multiple = 0;
    if (type === '布帘') {
      quantity = 3;
      if (widths > 1) {
        widths = widths - 1;
      }
    } else if (type === '成品帘') {
      quantity = 2.5;
    } else if (type === '电动成品帘') {
      quantity = 3;
    } else {
      quantity = 5;
    }
    prop2Multiple = Math.floor(widths / quantity);
    prop2Multiple = prop2Multiple + 1
    if (type === '布帘' && widths === 3) {
      prop2Multiple = prop2Multiple - 1
    }
    return prop2Multiple;
  }
  getTypePropOms = (type, width, category) => {
    let prop2Multiple = 1;
    let widths = Number(width);
    if (type === '布帘') {
      if (category.includes('梦幻帘')) {
        prop2Multiple = 1;
      } else {
        if (widths > 4) {
          prop2Multiple = 2;
        }
      }
    }
    return prop2Multiple;
  }

  //获取表格类型
  getCaseType = (types, prop) => {
    let caseType = '';
    if (types === '窗帘') {
      switch (prop) {
        case 'prop4':
          caseType = '层数';
          break;
        case 'prop5':
          caseType = '四角钩';
          break;
        case 'prop6':
          caseType = '墙钩';
          break;
        case 'prop7':
          caseType = '窗幔';
          break;
        case 'prop8':
          caseType = '超高';
          break;
        case 'prop9':
          caseType = '其他';
          break;
        default:
          break;
      }
    } else if (types === '墙布墙纸') {
      switch (prop) {
        case 'prop4':
          caseType = '规格';
          break;
        case 'prop5':
          caseType = '施工方式';
          break;
        case 'prop6':
          caseType = '施工工艺';
          break;
        case 'prop7':
          caseType = '拆旧';
          break;
        default:
          break;
      }
    } else if (types === '晾衣架') {
      switch (prop) {
        case 'prop4':
          caseType = '驱动方式';
          break;
        case 'prop5':
          caseType = '安装面';
          break;
        case 'prop6':
          caseType = '超高';
          break;
        case 'prop7':
          caseType = '拆旧';
          break;
        default:
          break;
      }
    }
    return caseType;
  }

  //第一次获取品类数据
  getOptionsRadios = () => {
    let optionsRadio = [];
    this.state.postData.forEach((pitem, pindex) => {
      let detail = this.state.datas.grid.filter(r => r.group === this.state.groupType[pindex])[0].detail;
      let detailAdd = detail.filter(r => r.name === this.state.detailType[pindex])[0].add;
      let newOptionsRadio = {};
      detailAdd.forEach(ditem => {
        switch (ditem.group) {
          case this.getCaseType(this.state.data.category, 'prop4'):
            newOptionsRadio[ditem.group] = JSON.parse(pitem.prop4)
            break;
          case this.getCaseType(this.state.data.category, 'prop5'):
            newOptionsRadio[ditem.group] = JSON.parse(pitem.prop5)
            break;
          case this.getCaseType(this.state.data.category, 'prop6'):
            newOptionsRadio[ditem.group] = JSON.parse(pitem.prop6)
            break;
          case this.getCaseType(this.state.data.category, 'prop7'):
            newOptionsRadio[ditem.group] = JSON.parse(pitem.prop7)
            break;
          case this.getCaseType(this.state.data.category, 'prop8'):
            newOptionsRadio[ditem.group] = JSON.parse(pitem.prop8)
            break;
          case this.getCaseType(this.state.data.category, 'prop9'):
            newOptionsRadio[ditem.group] = JSON.parse(pitem.prop9)
            break;
          default:
            break;
        }
      });
      optionsRadio.splice(pindex, 1, newOptionsRadio)
    })
    this.setState({
      optionsRadio: optionsRadio,
    })
  }
  //获取金额
  getAllPrices = () => {
    const { orderPrice, data, postData, type, price_app, shopPrice } = this.state;
    let orderPriceNUM = 0;
    orderPrice.forEach((item, index) => {
      if (type === '安装') {
        let multiple = this.getTypeProp2(postData[index].prop1, postData[index].width);
        let grapplePrice = this.getGrapplePrice(index, multiple);
        orderPriceNUM += item * Number(postData[index].prop2) * multiple - grapplePrice.price_app * Number(postData[index].prop2);
      } else {
        orderPriceNUM += item
      }
    });
    let price = orderPriceNUM > shopPrice ? orderPriceNUM : shopPrice;
    return price + price_app;
    // let price = orderPriceNUM + data.add_app + price_app;
    // return price > 79 ? price : 79
  }
  getAllPricesOms = () => {
    const { orderPrice_oms, postData } = this.state;
    let orderPriceNUM = 0;
    orderPrice_oms.forEach((item, index) => {
      let prop3 = postData[index].prop3 ? JSON.parse(postData[index].prop3).name : '';
      let prop9 = postData[index].prop9 ? JSON.parse(postData[index].prop9).name : '';
      let multiple = this.getTypePropOms(postData[index].prop1, postData[index].width, prop3);
      let grapplePrice = this.getGrapplePrice(index, multiple);
      orderPriceNUM += item * Number(postData[index].prop2) * multiple - grapplePrice.price_oms * Number(postData[index].prop2);
    });
    let price = orderPriceNUM;
    return price;
  }
  getPrices_app = (price_app) => {
    let type = this.state.data.type;
    if (type === '售后订单' || type === '二次上门') {
      return price_app
    }
    return price_app > this.state.shopPrice ? price_app : this.state.shopPrice
  }
  getPrices_oms = (price_oms) => {
    let type = this.state.data.type;
    if (type === '售后订单' || type === '二次上门') {
      return price_oms
    }
    return price_oms > this.state.workerPrice ? price_oms : this.state.workerPrice
  }
  //保存
  onOk = async (e) => {
    let { data, postData, type, modalType, orderPrice, orderPrice_oms, tbRemark, customTime, customRemark,
      videoList, selectVideo, videoPlay } = this.state;
    if (modalType === '订单详情') {
      this.onCancel();
      return;
    }
    // if (tbRemark) {
    //   data.name = data.name + 'DD' + tbRemark;
    // }
    data.platform = tbRemark;
    data.shop_remark = data.shop_remark + '✱' + customTime + '✱' + customRemark;
    data.serviceDatas = postData;
    if (type === '安装' && data.category !== '地板'
      && data.category !== '其他'
      && data.type !== '二次上门'
      && data.type !== '售后订单') {
      let price_app = this.getAllPrices();
      let price_oms = this.getAllPricesOms();
      data.price_app = price_app;
      data.price_oms = price_oms;
    } else {
      data.price_app = this.getPrices_app(data.price_app)
      data.price_oms = this.getPrices_oms(data.price_oms)
    }
    data.pic_remark = JSON.stringify(data.pic_remark);
    data.vod_remark = [];
    if (selectVideo.length > 0) {
      selectVideo.map(r => {
        let single = {
          name: videoList[r].name,
          videoId: videoList[r].videoId,
        };
        data.vod_remark.push(single)
      })
    }
    if (videoPlay.length > 0) {
      let videos = videoPlay.map(item => {
        return {
          name: item.name,
          videoId: item.videoId,
          isVideo: true,
        };
      })
      data.vod_remark = data.vod_remark.concat(videos);
    }
    data.vod_remark = JSON.stringify(data.vod_remark);
    if (!data.add_remark) {
      data.add_remark = ''
    }
    data.serviceDatas.forEach((item, index) => {
      let prop3 = item.prop3 ? JSON.parse(item.prop3).name : '';
      let prop9 = item.prop9 ? JSON.parse(item.prop9).name : '';
      let multiple = this.getTypeProp2(item.prop1, item.width);
      let multiple2 = this.getTypePropOms(item.prop1, item.width, prop3);
      if (data.category === '窗帘') {
        item.prop10 = orderPrice[index] * Number(item.prop2) * multiple + ''
        item.prop11 = orderPrice_oms[index] * Number(item.prop2) * multiple2 + ''
      } else if (data.category === '墙布墙纸' || data.category === '晾衣架') {
        item.prop8 = orderPrice[index] * Number(item.prop2) * multiple + ''
        item.prop9 = orderPrice_oms[index] * Number(item.prop2) * multiple + ''
      }
    })
    let serviceRoom = true;
    let serviceWidth = true;
    if (data.serviceDatas.length > 0) {
      data.serviceDatas.forEach(item => {
        if (item.room === 0) {
          serviceRoom = false;
        }
        if (item.width === 0) {
          serviceWidth = false;
        }
      })
    }
    if (!serviceRoom) {
      this.setState({
        postSpin: false
      })
      message.error('请填写窗帘名称');
      return;
    }
    if (!serviceWidth) {
      this.setState({
        postSpin: false
      })
      message.error('请填写长度');
      return;
    }
    // let methed = '修改'
    // if (data.state === '草稿箱') {
    //   methed = '下单'
    // }
    let res = await putOrder(data.id, e, data);
    if (res.status === 204) {
      if (e === '下单') {
        message.success('下单成功')
      }
      this.onOkSuccess(data)
    } else {
      message.error(res.data);
      return;
    }
  }

  //调用父组件方法传值
  onOkSuccess = (data) => {
    // let dataIndex = { ...this.state.dataIndex };
    // dataIndex.address = data.address;
    // dataIndex.city = data.city;
    // dataIndex.address = data.address;
    // dataIndex.district = data.district;
    // dataIndex.name = data.name;
    // dataIndex.phone = data.phone;
    // dataIndex.price_app = data.price_app + data.add_app;

    // dataIndex.province = data.province;
    // dataIndex.shop_remark = data.shop_remark;
    this.props.updateTable();
    this.onCancel();
  }
  //关闭modal
  onCancel = () => {
    this.setState({
      visible: false,
      postData: [{ ...serviceDatas }],
      orderPrice: [0],
      orderPrice_oms: [0],
      datas: {},
      optionsRadio: [],
      videoList: [],
      selectVideo: [],
      videoPlay: [],
    })
  }
}

export default IndexModal;